if (window.location.protocol === 'http:' || window.location.protocol === "http:") {
  // global.BASE_URL = 'http://portal.e-idara.com/API/public/api';
  global.BASE_URL = 'https://tsp.logicielity.com/API/public/api';
  // global.BASE_URL = 'http://192.168.18.18/eidara/API/public/api';
  //global.BASE_URL = '/API/public/app';

} else {
  global.BASE_URL = 'https://tsp.logicielity.com/API/public/api';
  //global.BASE_URL = 'https://tsp.logicielity.com/API/public';
  // global.BASE_URL = 'https://192.168.18.18/eidara/API/public/api';

  // global.BASE_URL = '/API/public/api';

}
