import './../../../index';
import React, {Component} from 'react';
import {
  Card, CardBody, CardHeader, Col, Row, Input, Form, FormGroup, Label, Table, Button, Alert
} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from "axios/index";
// import ReactTable from 'react-table';
// import jquery from 'jquery';
import PNotify from 'pnotify/dist/es/PNotify';
import lang from './../../../Language/en-us'

// import lang from './../../../Language/fr-ca'
class Tables extends Component {
  constructor(props) {
    super(props);
    this.getAttendanceDetail();
    this.state = {
      items: [],
      loading: true,
      success: false,
      error: false,
      validation: false,
      button: false,
      role_id: localStorage.getItem('role'),
    };
    this.submit_attandacnce = this.submit_attandacnce.bind(this);
  }

  getAttendanceDetail() {
    const account = {
      id: localStorage.getItem('id'),
      school_id: localStorage.getItem('school_id'),
      role: localStorage.getItem('role'),
      token: localStorage.getItem('token'),
      attendance_id: this.props.match.params.id
    };
    let uri = global.BASE_URL + '/VoucherListForFeeSubmission';

    axios.post(uri, account).then((response) => {

      this.setState({
        items: response.data,
        loading: false,
        button: true
      });
      if (response.data === '') {
        this.setState({
          button: false
        });
      }
    }).catch(error => {
      this.setState({
        button: false
      });
    });
  }

  submit_attandacnce(e) {
    e.preventDefault();
    this.setState({enable: false});
    var serialize = require('form-serialize');
    var form = document.querySelector('#form-data');
    var obj = serialize(form, {hash: true});
    // console.log(obj);
    let uri = global.BASE_URL + '/feeSubmitByVoucher';
    this.setState({
      button: false
    });
    axios.post(uri, obj).then((response) => {
      if (response.data === 'Already!') {
        PNotify.alert({
          text: "Some thing went wrong try again latter!",
          type: 'error'
        });
        // this.setState({error: true});
        // alert('You have already marked attendance');
        // this.props.history.push('/Attandance');
      } else if (response.data === 'success') {
        PNotify.alert({
          text: "Student fee successfully updated!",
          type: 'success'
        });
        // this.setState({success: true});
        // alert('You have successfully Submit Fee.');
        // window.location.reload();
        setInterval(function () {
          window.location.reload();
        }, 3000);
        // this.props.history.push('/AttendanceView/'+this.props.match.params.id);
      } else {
        PNotify.alert({
          text: "Some thing went wrong try again latter!",
          type: 'error'
        });
        this.setState({error: true});
      }
    }).catch(error => {
      this.setState({enable: false});
      console.log(error);
      PNotify.alert({
        text: "Some thing went wrong try again latter!",
        type: 'error'
      });
      // this.setState({error: true});
      // alert('Some thing went wrong try again latter.');
    });
  }

  render() {
    let success = this.state.success ? <div><Alert color="primary">
      Setting Successfull Updated
    </Alert></div> : <div></div>;
    let error = this.state.error ? <div><Alert color="danger">
      Some thing went wrong! try again latter
    </Alert></div> : <div></div>;
    let validation = this.state.validation ? <div><Alert color="warning">
      Fill all the fields
    </Alert></div> : <div></div>;
    let items = this.state.items;
    // let edit = items.find('date');
    return (
      <div className="animated fadeIn">

        <Row>
          <Col>
            <Card>

              <CardHeader>


                {success}
                {error}
                {validation}
                <i className="fa fa-align-justify"/> {lang[11]}
                <Link to={{pathname: "/StudentFee/singleVoucherFee"}} className="btn btn-info btn-sm pull-right"><i
                  className="icon-plus"/> {lang[49]}</Link>
              </CardHeader>

              <CardBody>
                <Form id="form-data">


                  <Table hover bordered striped responsive size="sm">

                    <thead>
                    <tr>
                      <th>Voucher Id</th>
                      <th>Roll #</th>
                      <th>Stduent Id</th>
                      <th>Student Name</th>
                      <th>Month Type</th>
                      <th>Fee Period</th>
                      <th>Fee</th>
                      <th>Pending Amount</th>
                      <th>Action</th>
                      <th>View</th>

                    </tr>
                    </thead>
                    <input type="hidden" name="student_id[]" id="student_id"
                           value={items.id}/>
                    <input type="hidden" name="school_id"
                           defaultValue={localStorage.getItem('school_id')}/>
                    <input type="hidden" name="user_id" defaultValue={localStorage.getItem('id')}/>
                    <input type="hidden" name="token" defaultValue={localStorage.getItem('token')}/>
                    <input type="hidden" name="remember_token"
                           defaultValue={localStorage.getItem('token')}/>
                    <input type="hidden" name="role" defaultValue={localStorage.getItem('role')}/>
                    <tbody>
                    {items.map(items =>
                      <tr key={items.id}>
                        <td>{items.id}</td>
                        <td>{items.roll_number}</td>
                        <td>{items.student_id}</td>
                        <td>{items.student_name}</td>
                        <td>{items.month_type}</td>
                        <td>{items.from_date} To {items.to_date}</td>
                        <td style={{flaot: "right"}}>{items.currency} {items.amount.toLocaleString()}</td>
                        <td style={{flaot: "right"}}>{items.currency} {items.pending_amount.toLocaleString()}</td>
                        <input type="hidden" name="student_id[]" id="student_id"
                               value={items.student_id}/>
                        <input type="hidden" name="voucher_id[]" id="voucher_id"
                               value={items.id}/>
                        <input type="hidden" name="detail_id[]" id="detail_id"
                               value={items.detail_id}/>
                        <input type="hidden" name="tran_id[]" id="tran_id"
                               value={items.tran_id}/>
                        <input type="hidden" name="amount[]" id="amount"
                               value={items.pending_amount}/>
                        <input type="hidden" name="month_type[]" id="month_type"
                               value={items.month_type}/>
                        <td>
                          <FormGroup row>
                            <Col md="1">
                            </Col>
                            <Col md="9">
                              <FormGroup check inline>
                                <Input className="form-check-input" id={"checkbox"} type="checkbox"
                                       name={items.id} value='Payyed'/>
                                <Label className="form-check-label" htmlFor="inline-radio1">Paid</Label>
                              </FormGroup>

                            </Col>
                          </FormGroup>
                        </td>
                        <td>
                          <Link style={{marginLeft: "4px"}} to={{pathname: `/StudentFee/singleVoucherFee/detail/${items.id}`}}
                                className="btn btn-primary btn-sm"><i className="fa fa-eye"/></Link>
                        </td>

                      </tr>
                    )}


                    </tbody>


                  </Table>
                  <Button type="submit" size="sm" onClick={this.submit_attandacnce} color="primary"
                          disabled={!this.state.button}><i
                    className="fa fa-dot-circle-o"/> Submit</Button>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

    );
  }

}

export default Tables;
